import { SaasTenantDto } from '@volo/abp.ng.saas/proxy';
import { eSaasComponents } from '@volo/abp.ng.saas';
import { Validators } from '@angular/forms';
import { ePropType, FormProp, FormPropList } from '@abp/ng.components/extensible';

const saasTenantNameProp = new FormProp<SaasTenantDto>({
  type: ePropType.String,
  name: 'name',
  id: 'name',
  displayName: 'Saas::TenantName',
  validators: () => [Validators.required],
  readonly: () => true,
  disabled: () => true,
});

export function tenantNameEditContributor(propList: FormPropList<SaasTenantDto>) {
  const index = propList.findIndex((i) => i.value.name === 'name');
  propList.dropByIndex(index);
  propList.addByIndex(saasTenantNameProp, index);
}

export const tenantEditFormPropContributors = {
  [eSaasComponents.Tenants]: [tenantNameEditContributor],
};
