import { RestService } from '@abp/ng.core';
import { APP_INITIALIZER, inject, Injectable } from '@angular/core';
import { AppSetDto } from './version-info.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserMenuService } from '@abp/ng.theme.shared';
import { VersionInfoComponent } from './version-info.component';
import { Router } from '@angular/router';
import { eUserMenuItems } from '@abp/ng.theme.basic';

@Injectable({
  providedIn: 'root',
})
export class VersionInfoService {
  apiName = 'Default';
  constructor(private restService: RestService) {}

  getAppSet = () =>
    this.restService.request<void, AppSetDto>(
      {
        method: 'GET',
        url: `/api/app/version`,
      },
      { apiName: this.apiName },
    );
}

export const CUSTOM_MENU_ITEM = [
  {
    provide: APP_INITIALIZER,
    useFactory: replaceMyAccountMenuItem,
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: addAboutToUserMenu,
    multi: true,
  },
];

export function replaceMyAccountMenuItem() {
  const userMenu = inject(UserMenuService);
  const router = inject(Router);

  return () => {
    userMenu.addItems([
      {
        id: eUserMenuItems.MyAccount,
        order: 100,
        textTemplate: {
          text: 'AbpAccount::MyAccount',
        },
        action: () => router.navigate(['account/manage']),
        visible: () => true,
      },
    ]);
  };
}

export function addAboutToUserMenu() {
  const userMenu = inject(UserMenuService);
  const modalService = inject(NgbModal);

  return () => {
    userMenu.addItems([
      {
        id: 'UserMenu.About',
        order: 101,
        textTemplate: {
          text: '::About',
        },
        action: () => {
          modalService.open(VersionInfoComponent, {
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            size: 'md',
          });
        },
        visible: () => true,
      },
    ]);
  };
}
