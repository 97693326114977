<div class="modal-header">
  <h4 class="modal-title d-flex justify-content-center">
    <abp-logo></abp-logo>
  </h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12 float-left">
      <b>{{ '::ProductVersion' | abpLocalization }}:</b>
      <span>
        <i *ngIf="loading" class="fa fa-spinner fa-pulse fa-fw ms-1"></i> {{ productVersion }}
      </span>
    </div>
  </div>

  <br />

  <p (click)="toggleDetails()" style="text-decoration: underline; cursor: pointer">
    {{ (isDetailsVisible ? '::HideDetails' : '::ShowDetails') | abpLocalization }}
  </p>

  <div *ngIf="isDetailsVisible">
    <div *ngFor="let pod of appSetDto?.pods" class="row">
      <div class="col-md-12 float-left">
        <b>{{ pod?.app }}:</b> {{ pod?.tag }}
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="closeModal()">
    {{ 'AbpUi::Close' | abpLocalization }}
  </button>
</div>
