import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule, VALIDATION_ERROR_TEMPLATE } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { BackButtonDirective } from './back-button/back-button';
import { ErrorComponent } from './form-validater';
import { SearchListComponent } from './search-list/search-list.component';

@NgModule({
  declarations: [BackButtonDirective, ErrorComponent, SearchListComponent],
  imports: [
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
  ],
  exports: [
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    BackButtonDirective,
    SearchListComponent,
    ErrorComponent,
  ],
  providers: [
    // other providers
    {
      provide: VALIDATION_ERROR_TEMPLATE,
      useValue: ErrorComponent,
    },
  ],
})
export class SharedModule {}
