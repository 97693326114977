import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

const configureRoutes = (routes: RoutesService) => () => {
  routes.add([
    {
      path: '/published-model',
      iconClass: 'fas fa-file-alt',
      order: 2,
      name: '::Menu:PublishedModels',
      layout: eLayoutType.application,
      requiredPolicy: 'SestekAI.PublishedModels',
    },
  ]);
};

export const PUBLISHEDMODELS_PUBLISHEDMODEL_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
