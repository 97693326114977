import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

const configureRoutes = (routes: RoutesService) => () => {
  routes.add([
    {
      path: '/',
      name: '::Menu:Home',
      iconClass: 'fas fa-home',
      order: 0,
      layout: eLayoutType.application,
    },
    {
      path: '/projects',
      name: '::Menu:Projects',
      iconClass: 'fas fa-home',
      order: 1,
      requiredPolicy: 'SestekAI.Projects',
      layout: eLayoutType.application,
    },
    {
      path: '/published-model',
      name: '::Menu:PublishedModels',
      iconClass: 'fas fa-file-alt',
      order: 2,
      requiredPolicy: 'SestekAI.PublishedModels',
      layout: eLayoutType.application,
    },
    {
      path: '/public-endpoint',
      name: '::Menu:PublicEndpoints',
      iconClass: 'fas fa-file-alt',
      order: 3,
      requiredPolicy: 'SestekAI.PublicEndpoints',
      layout: eLayoutType.application,
    },
  ]);
};

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];
